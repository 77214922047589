// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/css/all";
import 'bootstrap-icons/font/bootstrap-icons.css'
import "trix"
import "@rails/actiontext"
import 'trix/dist/trix.css';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

try {
  require("trix")
  require("@rails/actiontext")
} catch (error) {
  console.log(error)
}
